import styled, {DefaultTheme} from 'styled-components';
import T from '@brightlive/shared/styles/typography';

const S: Record<string, React.ElementType> = {};

const getInputBorder = (
  isFocused: boolean,
  error: boolean,
  disabled: boolean,
  theme: DefaultTheme
) => {
  if (disabled) {
    return `${theme.borderWidth.default} solid ${theme.borderColor.borderSubdued}`;
  } else if (error && isFocused) {
    return `${theme.borderWidth.focus} solid ${theme.borderColor.borderNegative}`;
  } else if (error) {
    return `${theme.borderWidth.default} solid ${theme.borderColor.borderNegative}`;
  } else if (isFocused) {
    return `${theme.borderWidth.focus} solid ${theme.borderColor.borderPrimary}`;
  } else {
    return `${theme.borderWidth.default} solid ${theme.borderColor.borderDefault}`;
  }
};

const getPadding = (isFocused, hasValue, prefixWidth, hasLabel) => {
  if (prefixWidth) {
    return isFocused || hasValue
      ? `16px 16px 0 ${16 + prefixWidth + 4}px`
      : `0 16px 0 ${16 + prefixWidth + 4}px`;
  } else {
    return (hasLabel && isFocused) || (hasLabel && hasValue)
      ? '16px 16px 0'
      : '0 16px';
  }
};

const getPrefixTop = (isFocused, hasValue) => {
  return isFocused || hasValue ? '25px' : '17px';
};

const getHeight = (v3: boolean) => {
  if (v3) {
    return '56px';
  }
  return '50px';
};

S.TextInputWrapper = styled.div(
  (props: {v3: boolean}) => `
  position: relative;
  width: 100%;
  padding-bottom: ${props.v3 ? 0 : '25px'};
`
);

S.TextInputWrapperInner = styled.div`
  position: relative;
`;

S.Label = styled.span(
  (props: {
    isFocused: boolean;
    disabled: boolean;
    inverse: boolean;
    theme: DefaultTheme;
  }) => `
  font-family: ${props.theme.fonts.primary};
  font-size: 11px;
  color: ${
    props.disabled
      ? props.theme.textColor[`text${props.inverse && 'Inverse'}Disabled`]
      : props.theme.textColor[`text${props.inverse ? 'Inverse' : 'Default'}`]
  };
  display: ${props.isFocused ? 'block' : 'none'};
  position: absolute;
  top: 10px;
  left: 16px;
  z-index: 10;
`
);

S.TextInput = styled(T.ParagraphMedium)(
  (props: {
    isFocused: boolean;
    hasValue: boolean;
    error: boolean;
    prefixWidth: boolean;
    isDate: boolean;
    hasLabel: boolean;
    disabled: boolean;
    inverse: boolean;
    v3: boolean;
    theme: DefaultTheme;
  }) => `

  && {
    width: 100%;
    height: ${getHeight(props.v3)};
    border: ${getInputBorder(
      props.isFocused,
      props.error,
      props.disabled,
      props.theme
    )};
    border-radius: ${props.theme.borderRadius.MD};
    padding: ${getPadding(
      props.isFocused,
      props.hasValue,
      props.prefixWidth,
      props.hasLabel
    )};
    margin: 0;
    box-sizing: border-box;
    background-color: ${
      props.disabled
        ? props.theme.backgroundColor.backgroundDisabled
        : 'transparent'
    };
    color: ${
      props.disabled
        ? props.theme.textColor[`text${props.inverse && 'Inverse'}Disabled`]
        : props.theme.textColor[`text${props.inverse ? 'Inverse' : 'Default'}`]
    };
    text-transform: ${props.isDate ? 'uppercase' : 'none'};
  }

  &::placeholder {
    color: ${
      props.disabled
        ? props.theme.textColor[`text${props.inverse && 'Inverse'}Disabled`]
        : props.theme.textColor[`text${props.inverse && 'Inverse'}Subdued`]
    };
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:invalid::-webkit-datetime-edit {
    color: ${
      props.disabled
        ? props.theme.textColor[`text${props.inverse && 'Inverse'}Disabled`]
        : props.theme.textColor[`text${props.inverse && 'Inverse'}Subdued`]
    };
  }
}
`
);

S.ErrorWrapper = styled.div`
  padding-top: 8px;
  display: flex;
`;

S.ErrorIcon = styled.img`
  width: 16px;
  margin-right: 8px;
  position: relative;
  top: -1px;
`;

S.Error = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.textColor.textNegative};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

S.Instructions = styled(S.Error)`
  color: ${props =>
    props.theme.textColor[`text${props.inverse && 'Inverse'}Subdued`]};
  padding-top: 8px;
`;

S.Prefix = styled(T.ParagraphMedium)(
  (props: {
    isFocused: boolean;
    hasValue: boolean;
    disabled: boolean;
    inverse: boolean;
    theme: DefaultTheme;
  }) => `
  && {
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    top: ${getPrefixTop(props.isFocused, props.hasValue)};
    left: 16px;
    color: ${
      props.disabled
        ? props.theme.textColor[`text${props.inverse && 'Inverse'}Disabled`]
        : props.theme.textColor[`text${props.inverse ? 'Inverse' : 'Default'}`]
    };
  }
`
);

S.CalendarIcon = styled.img`
  width: 18px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

S.CharCount = styled.p(
  (props: {disabled: boolean; inverse: boolean; theme: DefaultTheme}) => `
  font-family: ${props.theme.fonts.primary};
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: ${
    props.disabled
      ? props.theme.textColor[`text${props.inverse && 'Inverse'}Disabled`]
      : props.theme.textColor[`text${props.inverse && 'Inverse'}Subdued`]
  };
`
);

export default S;
