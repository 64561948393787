import React from 'react';
// Components
import TextInput from '../../TextInput';
import Button from '../../Button';
// Styles
import S from '../style';

export interface ForgotPasswordEmailContentProps {
  handleResetPassword: () => void;
  resetPasswordError: string;
  resetPasswordValue: string;
  setResetPasswordValue: (val: string) => void;
  setResetPasswordError: (val: string) => void;
  loading: boolean;
}

const ForgotPasswordEmailContent = ({
  handleResetPassword,
  resetPasswordError,
  resetPasswordValue,
  setResetPasswordValue,
  setResetPasswordError,
  loading,
}: ForgotPasswordEmailContentProps) => {
  const onResetPasswordChange = e => {
    e.preventDefault();
    const newValue = e.target.value;
    setResetPasswordValue(newValue);
    setResetPasswordError('');
  };

  return (
    <S.LoginWrapper>
      <S.LoginContent>
        <S.Title>Forgot password?</S.Title>
        <S.SubtitleLeft>
          Enter the email you used to sign up for your account and we’ll send
          you a special link.
        </S.SubtitleLeft>
        <S.ForgotInputWrapper>
          <S.InputWrapper>
            <TextInput
              label="Email"
              placeholder=""
              onChange={onResetPasswordChange}
              value={resetPasswordValue}
              error={resetPasswordError}
              v3={true}
            />
          </S.InputWrapper>
        </S.ForgotInputWrapper>

        <Button
          type="primary"
          disabled={!resetPasswordValue}
          text="Submit"
          loading={loading}
          onClick={handleResetPassword}
          width="100%"
          size="medium"
          v3={true}
        />
      </S.LoginContent>
    </S.LoginWrapper>
  );
};

export default React.memo(ForgotPasswordEmailContent);
