import React from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from '../../../styles/theme';
// Components
import TextInput from '../../TextInput';
import Button from '../../Button';
// Helpers
import {formatPhoneInput} from '../../../helpers/formatting';
import {validatePhoneNumber} from '../../../helpers/validators/isPhoneNumber';
import {validateEmail} from '../../../helpers/validators/isEmail';
// Styles
import S from '../style';

const LoginContent = ({
  loading,
  setPage,
  setSelected,
  selected,
  phoneValue,
  setPhoneValue,
  phoneError,
  setPhoneError,
  emailValue,
  setEmailValue,
  emailError,
  setEmailError,
  passwordValue,
  setPasswordValue,
  handleEmailLogin,
  handlePhoneLogin,
  isCreator = false,
}) => {
  const onEmailChange = e => {
    e.preventDefault();
    setEmailValue(e.target.value);
    setEmailError('');
  };

  const onPhoneChange = e => {
    e.preventDefault();
    const newValue = formatPhoneInput(e.target.value, phoneValue);
    setPhoneValue(newValue);
    setPhoneError('');
  };

  const onPasswordChange = e => {
    e.preventDefault();
    setPasswordValue(e.target.value);
  };

  const checkForErrors = () => {
    if (selected === 'phone') {
      if (!validatePhoneNumber(phoneValue)) {
        setPhoneError('Please enter a valid phone number');
        return;
      }
      handlePhoneLogin('security-code-login');
    } else {
      if (!validateEmail(emailValue)) {
        setEmailError('Please enter a valid email');
        return;
      }
      handleEmailLogin();
    }
  };

  const getAltLoginText = () => {
    if (selected === 'phone') {
      return 'Sign in with email';
    } else {
      return 'Sign in with phone number';
    }
  };

  const handleAltLogin = () => {
    if (selected === 'phone') {
      setSelected('email');
    } else {
      setSelected('phone');
    }
  };

  const getInputs = () => {
    if (selected === 'email') {
      return (
        <>
          <S.InputWrapper>
            <TextInput
              label="Email"
              placeholder="example@email.com"
              onChange={onEmailChange}
              value={emailValue}
              error={emailError}
              keyPressHandler={checkForErrors}
              v3={true}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <TextInput
              label="Password"
              placeholder=""
              onChange={onPasswordChange}
              value={passwordValue}
              keyPressHandler={checkForErrors}
              error=""
              type="password"
              v3={true}
            />
          </S.InputWrapper>
        </>
      );
    } else {
      return (
        <S.InputWrapper>
          <TextInput
            label="Phone number"
            placeholder="(000) 000-0000"
            onChange={onPhoneChange}
            value={phoneValue}
            error={phoneError}
            v3={true}
            keyPressHandler={checkForErrors}
          />
        </S.InputWrapper>
      );
    }
  };

  const disabled =
    (selected === 'phone' && phoneValue.length !== 14) ||
    (selected === 'email' && (!emailValue || !passwordValue));

  return (
    <ThemeProvider theme={theme}>
      <S.LoginWrapper>
        <S.LoginContent>
          <S.Title>Sign in to Bright</S.Title>
          {getInputs()}
          <S.Links>
            <div />
            {selected === 'email' && (
              <S.Reset
                onClick={() => {
                  setPage('reset');
                }}
              >
                Forgot Password?
              </S.Reset>
            )}
          </S.Links>
          <Button
            type="primary"
            v3={true}
            disabled={disabled}
            text="Sign in"
            loading={loading}
            onClick={checkForErrors}
            width="100%"
            size="medium"
          />
          <S.Terms>
            By logging in, you consent to our{' '}
            <a
              target="_blank"
              href={`${process.env.NEXT_PUBLIC_BOOKING_URL}/privacy-policy`}
              rel="noopener noreferrer"
            >
              <S.Link>Privacy Policy</S.Link>
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              href={`${process.env.NEXT_PUBLIC_BOOKING_URL}/terms-of-service`}
              rel="noopener noreferrer"
            >
              <S.Link>Terms & Conditions</S.Link>
            </a>
          </S.Terms>
          <S.OrWrapper>
            <S.OrLine />
            <S.OrBackground />
            <S.OrText>or</S.OrText>
          </S.OrWrapper>
          <S.AltLoginButtonWrapper isCreator={isCreator}>
            <Button
              type="secondary"
              text={getAltLoginText()}
              onClick={handleAltLogin}
              width="100%"
              size="medium"
              v3={true}
            />
          </S.AltLoginButtonWrapper>
          {!isCreator && (
            <S.AlternateText>
              New to Bright?{' '}
              <S.Link
                onClick={() => {
                  setPage('signup');
                }}
              >
                Sign up
              </S.Link>
            </S.AlternateText>
          )}
        </S.LoginContent>
      </S.LoginWrapper>
    </ThemeProvider>
  );
};

export default React.memo(LoginContent);
