import React from 'react';
// Styles
import S from '../style';

const ForgotPasswordSentContent = ({
  resetPasswordValue,
  handleResetPassword,
}) => {
  return (
    <S.LoginWrapper>
      <S.LoginContent>
        <S.Title>Forgot password?</S.Title>
        <S.Subtitle>
          <span>
            {`A link to reset your password has been sent to ${
              resetPasswordValue || 'user@email.com'
            }. If it doesn’t appear within a few minutes, check your spam folder or`}{' '}
          </span>
          <S.Link onClick={handleResetPassword}>try again</S.Link>
          <span>.</span>
        </S.Subtitle>
        <S.BottomContent>
          <S.AlternateText>
            Still having problems?{' '}
            <S.Link
              href="https://brightlive.zendesk.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact support
            </S.Link>
          </S.AlternateText>
        </S.BottomContent>
      </S.LoginContent>
    </S.LoginWrapper>
  );
};

export default React.memo(ForgotPasswordSentContent);
