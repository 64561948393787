import React, {useEffect} from 'react';
import {NextPage, NextPageContext} from 'next';
import firebase from 'firebase/compat/app';
// Components
import Toast from 'components/shared/Toast';
// Actions
import {checkAuth, refreshAuth} from 'library/auth';
import router from 'next/router';
import {useDispatch} from 'react-redux';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const withLayout = (WrappedComponent: any, redirect = false) => {
  const Wrapper: NextPage = (props: {
    children?: React.ReactNode;
    credential?: firebase.auth.UserCredential;
  }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      refreshAuth(dispatch, props.credential);
    }, []);
    return (
      <>
        <WrappedComponent {...props} />
        <Toast />
      </>
    );
  };

  Wrapper.getInitialProps = async (ctx: NextPageContext) => {
    const credential = await checkAuth(ctx.store.dispatch, ctx);
    if (redirect && credential) {
      const isServer = typeof window === 'undefined';
      if (isServer) {
        ctx?.res?.writeHead(302, {Location: '/sessions'});
        ctx?.res?.end();
      } else {
        router.push('/sessions');
      }
    }
    const componentProps =
      WrappedComponent.getInitialProps &&
      (await WrappedComponent.getInitialProps(ctx));

    return {...componentProps, credential};
  };

  return Wrapper;
};
