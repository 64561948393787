import {replaceAll} from '../formatting';

export const validatePhoneNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber.replaceAll(/[^0-9]/g, '');
  phoneNumber = replaceAll(phoneNumber.trim(), '-', '');
  phoneNumber = replaceAll(phoneNumber.trim(), '(', '');
  phoneNumber = replaceAll(phoneNumber.trim(), ')', '');
  phoneNumber = replaceAll(phoneNumber.trim(), ' ', '');
  return phoneNumber.length === 10;
};
