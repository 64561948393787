import {includes} from 'lodash';

export const replaceAll = (text, search, replace) => {
  return text.split(search).join(replace);
};

export const removeExtraPhoneChars = (value: string, isEmail = false) => {
  // if its a phone number - remove all letters
  if (!isEmail) {
    value = value.replaceAll(/[^0-9]/g, '');
  }
  value = replaceAll(value.trim(), '-', '');
  value = replaceAll(value.trim(), '(', '');
  value = replaceAll(value.trim(), ')', '');
  value = replaceAll(value.trim(), ' ', '');
  return value;
};

export const formatPhoneText = (value: string) => {
  if (!value) return '';
  value = removeExtraPhoneChars(value);

  if (!value) {
    value = '(';
  } else if (value.length >= 6) {
    value = value =
      '(' +
      value.slice(0, 3) +
      ') ' +
      value.slice(3, 6) +
      '-' +
      value.slice(6, 10);
  } else if (value.length >= 3) {
    value = '(' + value.slice(0, 3) + ') ' + value.slice(3);
  } else {
    value = '(' + value;
  }
  return value;
};

export const formatPhoneInput = (targetValue: string, oldValue: string) => {
  let newValue = '';
  const lastChar = oldValue[oldValue.length - 1];
  // if backspacing on a placeholder character make sure last number is removed
  if (
    targetValue.length < oldValue.length &&
    includes(['(', ')', '-'], lastChar)
  ) {
    newValue = formatPhoneText(targetValue.slice(0, -1));
    // if backspacing on a space - make sure 2 characters are removed
  } else if (targetValue.length < oldValue.length && lastChar === ' ') {
    newValue = formatPhoneText(targetValue.slice(0, -2));
  } else {
    // else format normally
    newValue = formatPhoneText(targetValue);
  }
  return newValue;
};

export const capitalize = val => {
  if (typeof val !== 'string') return '';
  return val.charAt(0)?.toUpperCase() + val.slice(1);
};

// shorten a string without cutting off words
export const shortenStringToWord = (string: string, maxLength: number) => {
  if (!string) return '';
  // if string is within max chars just return it
  if (string.length < maxLength) return string;
  // trim the string to the maximum length (-3 because we are adding '...')
  let trimmedString = string.substr(0, maxLength - 3);
  // re-trim if we are in the middle of a word
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );
  return trimmedString + '...';
};
