import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider} from 'styled-components';
import {uniqueId} from 'lodash';
import {theme} from '../../styles/theme';
// Images
import ErrorIcon from '../../images/error-icon.svg';
import CalendarIcon from '../../images/datepicker-calendar-icon.svg';
// Style
import S from './style';

/* eslint-disable  @typescript-eslint/no-explicit-any */

export interface TextInputProps {
  onFocus?: () => void;
  onBlur?: () => void;
  value: string;
  label?: string;
  v3?: boolean;
  placeholder?: string;
  keyPressHandler?: () => void;
  error?: string;
  prefix?: string;
  instructions?: string;
  icon?: string;
  type?: string;
  disabled?: boolean;
  inverse?: boolean;
  maxLength?: number;
  calendarOpen?: boolean;
  [x: string]: any;
}

const TextInput = ({
  onFocus,
  onBlur,
  value,
  label = '',
  v3 = false,
  placeholder = '',
  keyPressHandler,
  error = '',
  instructions = '',
  prefix = '',
  icon = '',
  type = 'text',
  disabled = false,
  inverse = false,
  maxLength = 524288,
  calendarOpen = false,
  ...props
}: TextInputProps) => {
  const prefixRef = useRef<HTMLParagraphElement>(null);
  const inputId = 'input' + uniqueId();
  const [isFocused, setIsFocused] = useState(false);
  const [prefixWidth, setPrefixWidth] = useState(0);

  // manually control calendar input unfocus
  useEffect(() => {
    if (!calendarOpen) {
      setIsFocused(false);
    }
  }, [calendarOpen]);

  useEffect(() => {
    if (prefixRef.current) {
      setPrefixWidth(prefixRef.current.clientWidth);
    } else {
      setPrefixWidth(0);
    }
  }, [prefix]);

  const onInputFocus = () => {
    setIsFocused(true);
    onFocus && onFocus();
  };

  const onInputBlur = () => {
    // if this input is being used as a date picker - dont unfocus until calendar is closed
    if (type === 'date' && calendarOpen) return;
    // everything else function normally
    setIsFocused(false);
    onBlur && onBlur();
  };

  const handleKeyPress = e => {
    if (e.which === 13 && keyPressHandler) {
      e.target.blur();
      keyPressHandler();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <S.TextInputWrapper v3={v3}>
        <S.Label
          id={inputId}
          isFocused={isFocused || value || type === 'date'}
          disabled={disabled}
          inverse={inverse}
        >
          {label}
        </S.Label>
        <S.TextInputWrapperInner>
          {prefix ? (
            <S.Prefix
              isFocused={isFocused}
              hasValue={value}
              disabled={disabled}
              inverse={inverse}
              ref={prefixRef}
            >
              {prefix}
            </S.Prefix>
          ) : null}
          <S.TextInput
            as="input"
            {...props}
            v3={v3}
            value={value}
            placeholder={isFocused ? placeholder : label}
            aria-placeholder={placeholder}
            aria-labelledby={inputId}
            aria-disabled={disabled}
            aria-errormessage={error}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            isFocused={isFocused}
            hasValue={value}
            onKeyPress={handleKeyPress}
            error={!!error}
            type={type}
            prefixWidth={prefixWidth}
            isDate={type === 'date'}
            disabled={disabled}
            inverse={inverse}
            required={type === 'date'}
            aria-required={type === 'date'}
            hasLabel={!!label}
            maxLength={maxLength}
          />
          {icon && <S.CalendarIcon src={icon} />}
          {type === 'date' && <S.CalendarIcon src={CalendarIcon} />}
          {maxLength !== 524288 && (
            <S.CharCount disabled={disabled} inverse={inverse}>
              {`${value?.length}/${maxLength}`}
            </S.CharCount>
          )}
        </S.TextInputWrapperInner>
        {instructions && !error && (
          <S.Instructions>{instructions}</S.Instructions>
        )}
        {!!error && (
          <S.ErrorWrapper error={!!error}>
            <div>
              <S.ErrorIcon src={ErrorIcon} />
            </div>
            <S.Error>{error}</S.Error>
          </S.ErrorWrapper>
        )}
      </S.TextInputWrapper>
    </ThemeProvider>
  );
};

export default TextInput;
